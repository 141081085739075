.dc-chart g.row text {fill: black;}

.dc-chart text.pie-slice {fill: black;}

#range-chart svg g g.axis.y { display: none; }

.number-block {
    font-size: 30px;
    background-color: #efb135;
    color: white;
}