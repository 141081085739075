@import 'variables';

.CalendarMonthGrid {
  background: $react-dates-color-white;
  z-index: $react-dates-z-index;
  text-align: left;
}

.CalendarMonthGrid--animating {
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -moz-transition: -moz-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  z-index: $react-dates-z-index + 1;
}

.CalendarMonthGrid--horizontal {
  position: absolute;
  left: 9px;
}

.CalendarMonthGrid--vertical {
  margin: 0 auto;
}

.CalendarMonthGrid--vertical-scrollable {
  margin: 0 auto;
  overflow-y: scroll;
}
