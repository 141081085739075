.btn-social {
  &.btn-round {
    padding: 8px 9px;
  }

  .fa {
    font-size: 18px;
    vertical-align: middle;
    display: inline-block;
    width: 20px;
    vertical-align: middle;
    display: inline-block;
  }
}

.btn-twitter { @include social-buttons-color($social-twitter) }
.btn-facebook { @include social-buttons-color($social-facebook) }
.btn-google { @include social-buttons-color($social-google) }
.btn-linkedin { @include social-buttons-color($social-linkedin) }
.btn-dribbble { @include social-buttons-color($social-dribbble) }
.btn-github { @include social-buttons-color($social-github) }
.btn-youtube { @include social-buttons-color($social-youtube) }
.btn-stumbleupon { @include social-buttons-color($social-stumbleupon) }
.btn-reddit { @include social-buttons-color($social-reddit) }
.btn-tumblr { @include social-buttons-color($social-tumblr) }
.btn-behance { @include social-buttons-color($social-behance) }
.btn-pinterest { @include social-buttons-color($social-pinterest) }
