@mixin paginationStyle($background-color, $text-color) {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;

  > li {
    display: inline;

    > a,
    > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      line-height: 1.4;
      color: $text-color;
      text-decoration: none;
      background: white;
      border: $border-thin solid $medium-dark-gray;
      border-radius: 38px;
    }

    > a,
    > span,
    &:first-child > a,
    &:first-child > span,
    &:last-child > a,
    &:last-child > span {
      margin: 0 2px;
      border-radius: 38px;
    }

    &.active > a,
    &.active > span,
    &.active > a:hover,
    &.active > span:hover,
    &.active > a:focus,
    &.active > span:focus {
      background-color: $background-color;
      color: $white-color;
      padding: 7px 13px;
    }
  }

  &.pagination-no-border {
    > li {
      > a,
      > span {
        border: 0;
      }
    }
  }
}

.pagination { @include paginationStyle($primary-bg, $primary-color); }
.pagination-primary { @include paginationStyle($primary-bg, $primary-color); }
.pagination-success { @include paginationStyle($success-bg, $success-color); }
.pagination-info { @include paginationStyle($info-bg, $info-color); }
.pagination-warning { @include paginationStyle($warning-bg, $warning-color); }
.pagination-danger { @include paginationStyle($danger-bg, $danger-color); }
