.panel-group {
  margin-bottom: 20px;

  .panel {
    border-radius: 0;
    border: 0;
    border-bottom: 0;
    box-shadow: none;
    background: $transparent-bg;
    margin-bottom: 10px;

    .panel-heading {
      border-bottom: 0;
      padding: 0;
      background: $transparent-bg;

      .caret {
        float: right;
        margin-top: 12px;
        margin-right: 15px;
        transition: all 150ms ease-in;
      }
    }

    .panel-title {
      font-size: 1rem;
      color: #25265F;
      letter-spacing: 0;
      line-height: 18px;
    }
  }

  .panel-heading + .panel-collapse > .panel-body {
    border-top: 0;
    padding: 10px 0;
    /* Anim pariatur cliche: */
    font-size: 1rem;
    color: #24292C;
    letter-spacing: 0;
    line-height: 18px;
  }
}

.tab-pane {
  padding: 15px 0;
}

a[aria-expanded="true"] .caret,
.btn[aria-expanded="true"] .caret,
a.dropdown-toggle[aria-expanded="true"] .caret {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}